
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ReservationModule from "@/store/reservation";
import HospitalModule from "@/store/hospital";
import CalendarModule from "@/store/calendar";
import AuthModule from "@/store/auth";
import FacilityService from "@/services/facilities";
import { CourseDay, CourseDetail, CourseInfo } from "@/@types/reservation";
import moment from "moment";
import { BusinessHours } from "@/@types/event";

@Component({
  name: "SentRegister",
  components: {
    Header,
    Footer,
  },
})
export default class CurseSelect extends Vue {
  private async selectCourse(course: CourseInfo) {
    ReservationModule.changeSelectedCourse(course);
    await this.businessHoursArrayFactory();
    this.$router.push({ name: "DateSelect" });
  }

  private loading = false;

  private get selectedCourse() {
    return ReservationModule.selectedCourse;
  }

  private convertDays(days: string[]) {
    const convertedDays = days.map((value) => {
      if (value === "Sun") {
        return 0;
      } else if (value === "Mon") {
        return 1;
      } else if (value === "Tue") {
        return 2;
      } else if (value === "Wed") {
        return 3;
      } else if (value === "Thu") {
        return 4;
      } else if (value === "Fri") {
        return 5;
      } else if (value === "Sat") {
        return 6;
      } else {
        return 99;
      }
    });
    return convertedDays;
  }

  private businessHourStringFactory(hour: number, minute: number) {
    const hours = moment().set("hour", hour).set("minute", minute).format("HH:mm");
    return hours;
  }

  private businessHoursArrayFactory() {
    const businessHoursArray: BusinessHours[] = [];
    const days = this.selectedCourse?.details[0].days;

    days?.forEach((courseDay: CourseDay) => {
      // 曜日のリストをstring→numberへ
      const daysOfWeek = this.convertDays(courseDay.available_days);
      // 終日休診の場合
      if (!courseDay.is_no_medical_all_day) {
        courseDay.times.forEach((time) => {
          const startTime = this.businessHourStringFactory(time.on_time.start_hour, time.on_time.start_minute);
          const endTime = this.businessHourStringFactory(time.on_time.end_hour, time.on_time.end_minute);
          businessHoursArray.push({
            daysOfWeek,
            startTime,
            endTime,
          });
        });
      }
    });
    CalendarModule.changeBusinessHours(businessHoursArray);
  }

  private get me() {
    return AuthModule.me;
  }

  private get hospitalId() {
    return HospitalModule._hospitalId;
  }

  private courses: CourseInfo[] = [];

  private async getCourses(id: string) {
    const res = await FacilityService.getHospitalCourses(id);
    const courses = res.data.data;
    let filteredActiveCourses: CourseInfo[] = [];
    courses.filter((course: CourseInfo) => {
      if (!course.visible) {
        return false;
      }
      course.details.forEach((detail: CourseDetail) => {
        let isSetEnd = false;
        if (detail.period && detail.period.end_year && detail.period.end_month && detail.period.end_date) {
          isSetEnd = true;
        }
        if (!isSetEnd) {
          filteredActiveCourses.push(course);
        }
        if (isSetEnd) {
          detail.days.forEach((day) => {
            day.times.forEach((timeObject) => {
              const endMonth = -timeObject.reception_time.end_month - 1;
              const endDate = -timeObject.reception_time.end_date;
              // item.datetimeの月からendMonthを引いて、endDateも引いて時間はendHourをセットする
              const endReceptionTime = moment()
                .set("year", detail.period.end_year)
                .set("month", detail.period.end_month)
                .set("date", detail.period.end_date)
                .set("hour", 23)
                .set("minute", 59)
                .set("second", 59)
                .add(endMonth, "month")
                .add(endDate, "day");

              // 現在時刻が受付時間を超えているかどうか

              if (moment(new Date()).isBefore(moment(endReceptionTime))) {
                filteredActiveCourses.push(course);
              }
            });
          });
        }
      });
    });
    // 重複しているコースを削除
    filteredActiveCourses = filteredActiveCourses.filter(
      (course, index, self) => index === self.findIndex((c) => c.id === course.id)
    );

    this.courses = filteredActiveCourses;
  }

  private async mounted() {
    ReservationModule.changeSelectedNumberOfPets(1);
    if (this.$route.query.id) {
      this.loading = true;
      ReservationModule.reservationReset();
      try {
        const res = await FacilityService.getHospital(this.$route.query.id as string);
        const hospital = res.data.data[0];
        HospitalModule.changeHospitalId(hospital.id);
        HospitalModule.changeHospitalName(hospital.name);
        ReservationModule.changeIsReservationFlow(true);
        if (!this.me) {
          this.$router.push({ name: "Login" });
        }
        this.getCourses(this.hospitalId);
        this.loading = false;
      } catch (error: any) {
        this.loading = false;
        throw new Error(error);
      }
    } else if (!this.$route.query.id && this.hospitalId) {
      try {
        this.getCourses(this.hospitalId);
      } catch (error: any) {
        throw new Error(error);
      }
    }
  }
}
